var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", sm: "8" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                  this.$store.getters.getRole == "operation" ||
                  this.$store.getters.getRole == "sales_manager"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: { color: "primary" },
                          on: { click: _vm.redirectUpdate },
                        },
                        [_vm._v(" Edit ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pt-3 pl-3" },
                [
                  _vm.api.isLoading
                    ? _c("v-skeleton-loader", {
                        ref: "skeleton",
                        attrs: { type: "table" },
                      })
                    : _vm._e(),
                  this.teamDescription != null
                    ? _c("div", [
                        _c("div", { staticClass: "text-h5 font-weight-bold" }, [
                          _vm._v(" " + _vm._s(_vm.teamDescription.name) + " "),
                        ]),
                        _c("p", { staticClass: "ml-3 mt-2" }, [
                          _vm._v(
                            " " + _vm._s(_vm.teamDescription.description) + " "
                          ),
                        ]),
                        _c("hr"),
                        _c("div", [
                          _c("div", { staticClass: "pb-3" }, [
                            this.teamDescription != null
                              ? _c(
                                  "p",
                                  { staticClass: "mt-3 ml-3 font-weight-bold" },
                                  [_vm._v(" Team leader ")]
                                )
                              : _vm._e(),
                            this.teamDescription != null
                              ? _c(
                                  "p",
                                  { staticClass: "mt-3 ml-3" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mx-2",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v(" fa-users ")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.leader.fname) +
                                        " " +
                                        _vm._s(_vm.leader.lname) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "p",
                              { staticClass: "ml-3" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mx-2", attrs: { small: "" } },
                                  [_vm._v(" mdi-email ")]
                                ),
                                _vm._v(" " + _vm._s(_vm.leader.email) + " "),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  !_vm.api.isLoading
                    ? _c("v-data-table", {
                        staticClass: "elevation--1 pa-2",
                        attrs: { items: this.teams, headers: this.headersTeam },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.memberName",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.fname) +
                                      " " +
                                      _vm._s(item.lname) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "v-toolbar",
                                    {
                                      attrs: { color: "secondary white--text" },
                                    },
                                    [_vm._v(" Members ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1271957836
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }